import React from "react"
import Helmet from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../components/layout"

export default function GDPR() {
  return (
    <Layout>
      <SEO title="EU General Data Protection Regulation Provisions | Compute Software"/>
      <Helmet></Helmet>
      <section className="terms-hero sub-hero jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="page-title">EU General Data Protection Regulation Provisions</h1>
          <p className="last-updated">Last updated: January 10, 2021</p>
        </div>
      </section>
      <section className="terms-content sub-general-content scrollable-content">
        <div className="container">
          <div className="row">
            <div className="terms col-lg-12">
                <main>
                  <div className="content">
                    <p>COMPUTE SOFTWARE EU GENERAL DATA PROTECTION REGULATION PROVISIONS </p>
                    <p>These EU General Data Protection Regulation Provisions (&ldquo;GDPR Provisions&rdquo;) supplement, are a schedule to and are incorporated into the terms of the services agreement (&ldquo;Agreement&rdquo;) currently between Compute Software, Inc. (&ldquo;Compute Software&rdquo;) and its customer (&ldquo;Customer&rdquo;) with regard to Compute Software&rsquo;s Processing of Personal Data on behalf of Customer in accordance with the requirements of all laws and regulations of the European Union, the European Economic Area and their member states. These GDPR Provisions will apply to the Processing of Personal Data by Compute Software in the course of providing the Compute Software software as a service solution (the &ldquo;Services&rdquo;). These GDPR Provisions do not otherwise disturb or impact any other agreement by and between the parties. The parties agree as follows: </p>
                    <h2>Process Personal Data as Instructed</h2>
                    <p>Except to the extent otherwise expressly stated in the Agreement: (i) Customer is the controller of Personal Data provided in the context of the Services; (ii) Customer hereby appoints Compute Software as a Processor to Process such Personal Data; (iii) Compute Software shall Process Personal Data as a Processor as necessary to perform its obligations under the Agreement (including any Order thereunder) and otherwise strictly in accordance with the written instructions of Customer, except where otherwise required by any applicable law. In the event that Compute Software is otherwise required to Process Personal Data by applicable law, Compute Software will notify Customer without undue delay and the parties will cooperate to ensure that Personal Data is processed to the minimum extent required by applicable law, unless such notification is prohibited by applicable law on important grounds of public interest. </p>
                    <h2>Confidentiality Restrictions.</h2>
                    <p>Compute Software shall ensure that any persons authorised to Process the Personal Data by it (including its employees, contractors, agents and subcontractors) have committed themselves to obligations of confidentiality which are at least commensurate with the confidentiality obligations contained in the Agreement or are under an appropriate statutory obligation of confidentiality; further Compute Software shall refer to Customer all requests for access to, amendment of, or deletion of Personal Data and any complaints by third parties regarding the handling of such Personal Data. </p>
                    <h2>Security Measures</h2>
                    <p>Taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of Processing as well as the risk of varying likelihood and severity for the rights and freedoms of natural persons, Compute Software shall implement appropriate technical and organizational security measures which are designed to ensure against (a) unauthorized access to, (b) unauthorized or unlawful alteration, disclosure, destruction or other unauthorized or unlawful Processing of, (c) accidental loss or destruction of, or (d) damage to, the Personal Data. Such technical and organizational security measures shall include as appropriate and without limitation (i) industry standard measures to ensure the ongoing confidentiality, integrity, availability and resilience of Processing systems and services, (ii) the ability to restore the availability and access to Personal Data in a timely manner in the event of a physical or technical incident, and (iii) a Process for regularly testing, assessing and evaluating the effectiveness of technical and organizational measures for ensuring the security of the Processing. </p>
                    <h2>Data Subjects</h2>
                    <p>Taking into account the nature of the Processing, Compute Software shall at all times cooperate with and assist Customer in so far as possible to enable Customer to meet applicable deadlines and requirements under Applicable Data Protection Laws in relation to a Data Subject&rsquo;s right (i) of rectification of Personal Data, (ii) of erasure of Personal Data, (iii) to restriction of Processing of Personal Data, (iv) to portability of Personal Data, (v) to object to the lawfulness of the Processing of Personal Data, and (vi) to not be subject to a decision based solely on automated Processing. Compute Software shall notify Customer, as soon as possible, of any request made by a Data Subject to access Personal Data and shall at all times cooperate with and provide Customer with any assistance it may require in order to execute Customer&rsquo;s obligations under Applicable Data Protection Laws. </p>
                    <h2>Notification of Security Incidents</h2>
                    <p>In the case of a Personal Data Breach, Compute Software shall promptly, without undue delay, notify Customer of the Personal Data Breach. To the extent that Compute Software has access to such information at the time of the notification, such notification shall (i) describe the nature of the Personal Data Breach, without limitation, the categories and approximate number of Data Subjects concerned and the categories and approximate number of Personal Data records concerned, (ii) describe the likely consequences of the Personal Data Breach, and (iii) describe the measures proposed to be taken by Compute Software to address the Personal Data Breach (provided Compute Software will only implement measures such as contacting Data Subjects or notifying the relevant regulator on the instruction of Customer), including, where appropriate, measures to mitigate its possible adverse effects. Where, but only to the extent that it is not possible to provide such information at the same time as the notification of the Personal Data Breach, the information may be provided at a later time but in event as soon as reasonably practicable, and in any event, in sufficient time to enable Customer to meet the applicable deadlines under Applicable Data Protection Laws. </p>
                    <h2>Return/Destruction of Personal Data</h2>
                    <p>In addition to and without prejudice to any obligations set forth in the confidentiality section of these terms or the Agreement, at Customer direction and to the extent feasible Compute Software shall delete or return all Personal Data to Customer at the end of the provision of the applicable Services to which the Processing relates or otherwise upon request, and where and to the extent feasible delete all existing copies held by Compute Software (unless applicable law requires the storage of such Personal Data by Compute Software) and provide confirmation in writing to Customer that it has complied with any such request of Customer. </p>
                    <h2>Provide Information as Needed</h2>
                    <p>Permit Customer to take all necessary steps to ensure compliance under Applicable Data Protection Laws, including, but not limited to, making available to the Customer all information necessary and allowing for audits and inspections if and to the extent necessary to so comply. </p>
                    <h2>Locations and Consent to Subprocessing</h2>
                    <p>Compute Software shall not subcontract any Processing of Personal Data which Compute Software processes as a data processor on behalf of Customer as a data controller within the scope of these terms to a third party (&ldquo;Subprocessor&rdquo;) without Customer&rsquo;s prior consent. Customer hereby consents to Compute Software engaging (and/or dismissing) subprocessors to Process the Personal Data provided that: (i) Compute Software provides notice by posting at <a href="https://www.google.com/url?q=https://www.computesoftware.com/subprocessors/&amp;sa=D&amp;source=editors&amp;ust=1612372031603000&amp;usg=AOvVaw2UosxH5RD5WIonr4egJyGd">https://www.computesoftware.com/subprocessors/</a>&nbsp;; (ii) Customer may object to the addition of a new Subprocessor appointed by Compute Software if Customer, in its reasonable discretion, believes that such new Subprocessor in Processing the Personal Data would not comply with these terms, the ADPL, or the Agreement, in which case the parties agree to negotiate in good faith a mutually agreeable alternative. If the objection is valid and no such alternative is agreed within two months of the objection, Customer will have the right to terminate, without penalty, any Services for which personal data would be Processed by the new Subprocessor against which the objection was raised; (iii) Compute Software shall require by written agreement each Subprocessor&rsquo;s compliance with the ADPL and will ensure it has the contractual terms in place with such Subprocessor that are required by the ADPL; and, (iv) Compute Software shall remain responsible for the Subprocessor&rsquo;s performance under these terms and the Agreement. Compute Software will list the locations to which the Personal Data may be transferred in connection with the Service on the aforementioned webpage and shall at all times conduct such transfer in a manner compliant with the ADPL. </p>
                    <h2>Definitions</h2>
                    <p>For the purposes of this rider: </p>
                    <p>9.1. &ldquo;Personal Data&rdquo;, &ldquo;Process/Processing&rdquo;, &ldquo;Controller&rdquo;, &ldquo;Processor&rdquo;, and &ldquo;Data Subject&rdquo; shall have the same meaning as provided under Applicable Data Protection Laws. </p>
                    <p>9.2. &ldquo;Applicable Data Protection Laws&rdquo; or &ldquo;ADPL&rdquo; means any laws applicable to Compute Software or Customer in relation to the Processing of Personal Data under this Agreement, including: (i) the legislation and regulations implementing Directive 95/46/EC, (ii) on and with effect from 25 May 2018, the General Data Protection Regulation (Regulation (EU) 2016/679 of the European Parliament and the Council of 27 April 2016) and (iii) any guidance and/or codes of practice issued by the Data Protection Commissioner or other relevant supervisory authority, including the European Data Protection Board. </p>
                    <p>9.3. &ldquo;Personal Data Breach&rdquo; shall have the meaning set forth in the General Data Protection Regulation (Regulation (EU) 2016/679 of the European Parliament and the Council of 27 April 2016).</p>                  
                  </div>
                  <nav className="table-of-contents" >
                    <h3>Table of Contents</h3>
                    <div id="toc"></div>
                  </nav>
                </main>
              </div>
            </div>
        </div>
      </section>
    </Layout>
  )
}
