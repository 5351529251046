import React from "react"
import CookieConsent from 'react-cookie-consent';

import Header from "../components/global/header";
import Footer from "../components/global/footer";

import "../styles/vendor/normalize.css"
import "../styles/vendor/bootstrap.css"
import "../styles/vendor/bootstrap-theme.min.css"
import "../styles/vendor/bootstrap-main.css"
import "../styles/vendor/bootstrap-toc.css"
import "../styles/styles.scss"

export default function Layout({ children }) {
  return (
  	<div id="parent">
		<Header/>
	    <div className="main-content">
	      {children}
	    </div>
	    <Footer/>
	    <CookieConsent
	    	disableStyles={true}
        	location="bottom"
          	buttonText="Accept"
          	declineButtonText="Decline"
          	cookieName="gatsby-gdpr-google-tagmanager"
          	buttonClasses="cookie-button"
  			containerClasses="cookie-container"
  			contentClasses="cookie-content">
			We use cookies to collect data to improve your experience on our site, as described in our <a href="/privacy-policy">Privacy Policy</a>. By continuing to browse this site, you agree to this use.
		</CookieConsent>
    </div>
  )
}